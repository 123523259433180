<div *ngIf="serverData$ | async as serverData" class="grid-container">
    <h1>{{ serverData?.name }}</h1>
    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="status-card">
                <mat-card-header>
                    <mat-card-title *ngIf="serverData?.status === 'stopped'" color="accent">
                        <mat-icon class="status-icon">stop</mat-icon>
                        Stopped
                    </mat-card-title>
                    <mat-card-title *ngIf="serverData?.status === 'running'" color="accent">
                        <mat-icon class="status-icon">play_arrow</mat-icon>
                        Running
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <h2 class="res-name">CPU</h2>
                    <p>{{ serverData?.cpu * 100 | number: '1.2-2' }} %</p>
                    <mat-progress-bar mode="determinate" value="{{ serverData?.cpu * 100 }}"></mat-progress-bar>
                    <h2 class="res-name">RAM</h2>
                    <p>{{ serverData?.mem / 1024 / 1024 / 1024 | number: '1.2-2' }} Go / {{ serverData?.maxmem / 1024 / 1024 / 1024 | number: '1.2-2' }} Go</p>
                    <mat-progress-bar mode="determinate" value="{{ serverData?.mem / serverData?.maxmem * 100 }}"></mat-progress-bar>
                    <h2 class="res-name">Disk capacity</h2>
                    <p>{{ serverData?.maxdisk / 1024 / 1024 / 1024 | number: '1.2-2' }} Go</p>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-card class="status-card">
                <mat-card-header>
                    <mat-card-title>Actions</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <button mat-button color="primary" [disabled]="serverData?.status === 'running'" (click)="start(serverData)">Start</button>
                    <button mat-button color="warn" [disabled]="serverData?.status === 'stopped'" (click)="stop(serverData)">Stop</button>
                </mat-card-content>
                <mat-card-header>
                    <mat-card-title>Reinstall</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <button mat-button color="accent">Mount ISO</button>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
