<mat-toolbar color="primary">
  <button mat-icon-button>
    <mat-icon (click)="sidenav.toggle()" class="hamburger">menu</mat-icon>
  </button>
  <div>{{ title }}</div>

  <span class="fill-remaining-space"></span>

  <button mat-button [matMenuTriggerFor]="beforeMenu" class="usernameBtn">
    <div *ngIf="authService.getUser()" [hidden]="isBiggerScreen()">
      {{ authService.getUser().getFullName() }}
      <p class="mail">{{ authService.getUser().getMail() }}</p>
    </div>
  </button>

  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <button mat-menu-item disabled>Settings</button>
    <button mat-menu-item (click)="authService.logout()">Log out</button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container>
  <!-- Sidenav -->
  <mat-sidenav
    #sidenav
    [mode]="isBiggerScreen() ? 'over' : 'side'"
    [(opened)]="opened"
    [fixedInViewport]="false"
    [fixedTopGap]
    style="min-width: 15%"
  >
    <mat-nav-list *ngIf="authService.signedIn">
      <mat-list-item routerLink="/" routerLinkActive="active">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <h4 mat-line>Dashboard</h4>
      </mat-list-item>
      <mat-list-item routerLink="/" routerLinkActive="active">
        <mat-icon mat-list-icon>shopping_cart</mat-icon>
        <h4 mat-line>Order</h4>
      </mat-list-item>
      <mat-list-item routerLink="/" routerLinkActive="active">
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line>User Profile</h4>
      </mat-list-item>
      <mat-list-item (click)="authService.logout()">
        <mat-icon mat-list-icon>logout</mat-icon>
        <h4 mat-line>Logout</h4>
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list *ngIf="!authService.signedIn">
      <mat-list-item routerLink="/" routerLinkActive="active">
        <mat-icon mat-list-icon>login</mat-icon>
        <h4 mat-line>Login</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Main content -->
  <mat-sidenav-content>
    <div style="margin: 15px">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
