<mat-card *ngIf="!toVerifyEmail">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2>Register</h2>
      <mat-error *ngIf="loginInvalid">
        The username and password were not recognised
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="First name" formControlName="firstname" required>
        <mat-error>
          Please provide a valid name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Last name" formControlName="lastname" required>
        <mat-error>
          Please provide a valid name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Phone (international format: +336... for France)" formControlName="phone" required>
        <mat-error>
          Please provide a valid phone number
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Address" formControlName="address" required>
        <mat-error>
          Please provide a valid address
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Zip code" formControlName="zip" required>
        <mat-error>
          Please provide a valid zip code
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="City" formControlName="city" required>
        <mat-error>
          Please provide a valid city name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="username" required>
        <mat-error>
          Please provide a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error>
          Please provide a valid password
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary">Sign up</button>
    </form>
  </mat-card-content>
</mat-card>


<mat-card *ngIf="toVerifyEmail">
  <mat-card-content>
    <form [formGroup]="formVerify" (ngSubmit)="verify()">
      <h2>Verify email</h2>
      <mat-error *ngIf="verifyInvalid">
        The verification code is not valid.
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput type="number" placeholder="Enter the code" formControlName="code" required>
        <mat-error>
          Please provide a valid verification code received by email
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary">Verify</button>
    </form>
  </mat-card-content>
</mat-card>