import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VMState, VMStatus } from 'src/app/models/vm';
import { VmService } from 'src/app/services/vm.service';

@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.css'],
})
export class ServerComponent implements OnInit {
  serverData$: Observable<VMStatus>;
  timer = 3000;

  constructor(
    private route: ActivatedRoute,
    private vmService: VmService,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params: Params) => {
      this.serverData$ = this.vmService
        .getStatus(Number.parseInt(params.id))
        .pipe(
          catchError((error) => {
            this.snackbar.open(error, '', {
              horizontalPosition: 'end',
              verticalPosition: 'bottom',
            });
            return of(null);
          })
        );
    });
    //console.log(await this.serverData$.toPromise());
    // setInterval(() => {
    //   if (!window.document.hidden && this.serverData.status === VMState.running)
    //     this.refresh();
    //   if (this.timer < 10) this.timer += 500;
    // }, this.timer);
  }

  async start(serverData: VMStatus) {
    if (serverData.status === VMState.stopped) {
      await this.vmService.start(serverData.vmid).toPromise();
      setTimeout(() => {
        this.refresh(serverData);
      }, 500);
    }
  }

  async stop(serverData: VMStatus) {
    if (serverData.status === VMState.running) {
      await this.vmService.stop(serverData.vmid).toPromise();
      setTimeout(() => {
        this.refresh(serverData);
      }, 250);
    }
  }

  refresh(serverData: VMStatus) {
    this.serverData$ = this.vmService.getStatus(serverData.vmid).pipe(
      catchError((error) => {
        this.snackbar.open(error, '', {
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
        });
        return of(null);
      })
    );
  }
}
