export class User {
  constructor(obj: any) {
    Object.assign(this, obj);
  }

  address: string;
  email: string;
  family_name: string;
  given_name: string;
  phone_number: string;
  sub: string;

  getFullName() {
    return this.given_name + ' ' + this.family_name;
  }

  getMail() {
    return this.email;
  }
}
