export class VMStatus {
  constructor(obj: any) {
    Object.assign(this, obj);
  }
  agent: Boolean;
  cpu?: Number;
  cpus: Number;
  maxdisk: Number;
  mem?: Number;
  maxmem: Number;
  name: String;
  uptime: Number;
  vmid: Number;
  status: VMState;
}

export enum VMState {
  stopped = 'stopped',
  running = 'running',
}
