import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  formVerify: FormGroup;
  public loginInvalid: boolean;
  public verifyInvalid: boolean;
  private returnUrl: string;
  toVerifyEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '';
    this.form = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: [
        '',
        [Validators.required, Validators.pattern('^\\+[1-9]\\d{1,14}$')],
      ],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      username: ['', Validators.email],
      password: ['', Validators.required],
    });
    if (this.authService.signedIn === true) {
      this.router.navigate([this.returnUrl]);
    }
    this.formVerify = this.fb.group({
      code: [
        '',
        [Validators.required, Validators.min(0), Validators.max(999999)],
      ],
    });
  }

  async onSubmit() {
    this.loginInvalid = false;
    if (this.form.valid) {
      const firstname = this.form.get('firstname').value;
      const lastname = this.form.get('lastname').value;
      const phone = this.form.get('phone').value;
      const address = this.form.get('address').value;
      const zip = this.form.get('zip').value;
      const city = this.form.get('city').value;
      const username = this.form.get('username').value;
      const password = this.form.get('password').value;
      let newu = new User({
        address: address + ' ' + zip + ' ' + city,
        email: username,
        family_name: lastname,
        given_name: firstname,
        phone_number: phone,
      });
      this.authService
        .register(newu, password)
        .then(() => (this.toVerifyEmail = true))
        .catch(() => (this.loginInvalid = true));
    }
  }
  verify() {
    this.verifyInvalid = false;
    if (this.formVerify.valid) {
      const code: Number = this.formVerify.get('code').value;
      this.authService
        .confirm(this.form.get('username').value, code.toString())
        .then(() => this.router.navigate([this.returnUrl]))
        .catch(() => (this.verifyInvalid = true));
    }
  }
}
