import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VMStatus } from '../models/vm';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VmService {
  private apiURL = environment.apiBaseURL + '/vms/';
  constructor(private http: HttpClient) {}

  getStatus(id: Number): Observable<VMStatus> {
    return this.http.get<VMStatus>(this.apiURL + id + '/status').pipe(
      catchError((error) => {
        let errorMsg: string;
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }
        return throwError(errorMsg);
      })
    );
  }
  start(id: Number): Observable<void> {
    return this.http.post<void>(this.apiURL + id + '/start', {});
  }
  stop(id: Number): Observable<void> {
    return this.http.post<void>(this.apiURL + id + '/stop', {});
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      case 502: {
        return `Unable to contact the server`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
