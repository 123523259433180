import { environment } from './environments/environment';
const cfg = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_l0JpdQlmB',
  userPoolWebClientId: '3tb52ml8jg9gn75ujig9ju2ihg',
  oauth: {
    domain: 'proxmox-dev-frontend.auth.eu-central-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: environment.frontendURL,
    redirectSignOut: environment.frontendURL,
    responseType: 'code',
  },
};

export default cfg;