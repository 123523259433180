import { Injectable, ApplicationRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public signedIn = false;
  public username = '';
  public user: User;

  constructor(
    private app: ApplicationRef,
    private router: Router,
    private ngZone: NgZone
  ) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.user = new User(user.attributes);
        this.signedIn = true;
      })
      .catch(() => {
        this.signedIn = false;
        this.user = null;
      });
    this.app.tick();
  }

  async logout() {
    this.user = null;
    this.signedIn = false;
    await Auth.signOut();
    this.router.navigate(['login']);
  }

  async login(username: string, password: string): Promise<void> {
    return new Promise<void>((success, fail) =>
      Auth.signIn(username, password)
        .then((user) => {
          this.user = new User(user.attributes);
          this.signedIn = true;
          success();
        })
        .catch(() => fail())
    );
  }
  register(user: User, password: string) {
    return Auth.signUp({
      username: user.email,
      password: password,
      attributes: {
        email: user.email,
        family_name: user.family_name,
        given_name: user.given_name,
        address: user.address,
        phone_number: user.phone_number,
      },
    });
  }

  confirm(username: string, code: string) {
    return Auth.confirmSignUp(username, code, { forceAliasCreation: true });
  }

  refresh() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.user = new User(user.attributes);
        this.signedIn = true;
        this.ngZone.run(() => this.router.navigate(['']));
      })
      .catch(() => {
        this.signedIn = false;
        this.user = null;
        this.ngZone.run(() => this.router.navigate(['login']));
      });
    this.app.tick();
  }

  async getUserAttributes() {
    return await Auth.currentUserInfo();
  }

  openFederatedLogin() {
    Auth.federatedSignIn();
  }

  getUser() {
    return this.user;
  }

  currentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }
}
