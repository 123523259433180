import { Component, HostListener, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from './services/authentication.service';
import { VmService } from './services/vm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Proxmox Frontend';
  opened = true;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    public vmService: VmService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (window.innerWidth < 768) {
      this.sidenav.fixedTopGap = 56;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 64;
      this.opened = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      this.sidenav.fixedTopGap = 56;
      this.opened = false;
    } else {
      this.sidenav.fixedTopGap = 64;
      this.opened = true;
    }
  }

  isBiggerScreen() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 768) {
      return true;
    } else {
      return false;
    }
  }

  /*async ngOnInit() {
    console.log(await this.vmService.getStatus(202).toPromise());
    let test: VMStatus = await this.vmService.getStatus(202).toPromise();
    console.log(test.status);
    await this.vmService.start(202).toPromise();
    test = await this.vmService.getStatus(202).toPromise();
    console.log(test.status);
    await this.vmService.stop(202).toPromise();
    setTimeout(async () => {
      test = await this.vmService.getStatus(202).toPromise();
      console.log(test.status);
    }, 2000); 
  }*/
}
